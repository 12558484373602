import React, { useState, useContext } from 'react'
import moment from 'moment'
import { ButtonV3, Modal, theme } from '@provi/provi-components'
import { TemplateContext } from '../../../../components/template/context'
import { history } from '../../../../navigation/history'
import { LOCALSTORAGE_TOKENS } from '../../../../constants'
import ImageModal1 from '../../../../assets/images/image-modal-1.png'
import ImageModal2 from '../../../../assets/svg/modal-1.svg'
import ImageModal3 from '../../../../assets/svg/modal-2.svg'
import {
  ItemInvoice,
  Left,
  Text,
  Footer,
  TextTitle,
  TextSimple,
  Right,
  Status,
  Header,
  ContainerModal,
  SubTitle,
  Bold,
  Image,
  WrapperMobileModal,
  RowModal,
  LinkOpenModal
} from './styles'

export const modals = ({ isMobile, setModalActive, setIsShowModal }) => [
  {
    title: 'Para retirar a sua mesada, você vai precisar:',
    children: (
      <>
        {isMobile ? (
          <WrapperMobileModal>
            <RowModal>
              <TextTitle>1. Tirar uma selfie</TextTitle>
              <TextSimple>Para comprovar que é você quem está solicitando a mesada.</TextSimple>
            </RowModal>
            <RowModal>
              <TextTitle>2. Assinar o contrato</TextTitle>
              <TextSimple>O contrato é 100% digital, e se você tiver avalista, ele também vai precisar assinar.</TextSimple>
            </RowModal>
            <RowModal>
              <TextTitle>3. Aguardar a mesada</TextTitle>
              <TextSimple>Fique tranquilo! É bem rápido.</TextSimple>
            </RowModal>
          </WrapperMobileModal>
        ) : (
          <Image wide src={ImageModal1} />
        )}
      </>
    ),
    footer: <ButtonV3 type="button" text="Avançar" onClick={() => setModalActive(1)} />
  },
  {
    title: 'Selfie correta',
    'sub-title': (
      <SubTitle>
        Estar bem iluminada e nítida. <Bold>Sem filtros!</Bold>
      </SubTitle>
    ),
    children: <Image src={ImageModal2} />,
    footer: <ButtonV3 type="button" text="Avançar" onClick={() => setModalActive(2)} />
  },
  {
    title: 'Selfie errada',
    'sub-title': (
      <SubTitle>
        <Bold>Não use</Bold> acessórios que escondam o rosto!
      </SubTitle>
    ),
    children: <Image src={ImageModal3} />,
    footer: (
      <ButtonV3
        type="button"
        text="Avançar"
        onClick={() => {
          history.push('/provi-sign/selfie')
        }}
      />
    )
  },
  {
    title: 'Ainda não terminamos de analisar seu pedido de aumento ⏰',
    'sub-title': '',
    children:
      'Não podemos concluir sua solicitação de Mesada enquanto a análise estiver em andamento. Aguarde alguns minutos antes de tentar novamente.',
    footer: (
      <ButtonV3
        type="button"
        text="Voltar para o Painel"
        onClick={() => {
          if (setIsShowModal) setIsShowModal(false)
        }}
      />
    )
  }
]

export const CardAvailable = ({ item }) => {
  const { isMobile } = useContext(TemplateContext)
  const [isShowModalPanel, setIsShowModalPanel] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [modalActive, setModalActive] = useState(0)

  return (
    <ItemInvoice>
      <Header>
        <Left>
          <TextTitle>Mesada de {moment(item.date, 'DD/MM/YYYY').format('MMMM')}</TextTitle>
          {isMobile ? (
            <LinkOpenModal onClick={() => setIsShowModalPanel(true)}>Visualizar cronograma</LinkOpenModal>
          ) : (
            <>
              <TextSimple bold color={theme.colors.grey600}>
                Abertura para retirada: {item.availableDate}
              </TextSimple>
              <TextSimple bold color={theme.colors.grey600}>
                Data prevista para depósito: {item.scheduledDate}
              </TextSimple>
            </>
          )}
        </Left>
        <Right>
          <Text>Disponível</Text>
          <Status color={theme.colors.blue600} />
        </Right>
      </Header>
      <Footer>
        <Left>
          <ButtonV3
            marginVertical={0}
            marginHorizontal={0}
            text="Retirar agora"
            onClick={() => {
              localStorage.setItem(LOCALSTORAGE_TOKENS.loan_option_ids, JSON.stringify(item.LoanOptionsIds))
              localStorage.setItem(LOCALSTORAGE_TOKENS.invoice_active, item.id)
              setIsShowModal(true)
            }}
          />
        </Left>
        <Right>
          <Text>
            {item.number}/{item.amountOfItems}
          </Text>
        </Right>
      </Footer>
      {isShowModalPanel && (
        <Modal
          title="Cronograma"
          onDismiss={() => {
            setIsShowModalPanel(false)
          }}
        >
          <TextSimple bold color={theme.colors.grey600}>
            Abertura para retirada: {item.availableDate}
          </TextSimple>
          <TextSimple bold color={theme.colors.grey600}>
            Data prevista para depósito: {item.scheduledDate}
          </TextSimple>
        </Modal>
      )}
      {isShowModal && (
        <Modal
          title={modals({ setModalActive, isMobile })[modalActive].title}
          footer={modals({ setModalActive, isMobile })[modalActive].footer}
          onDismiss={() => {
            setModalActive(0)
            setIsShowModal(false)
          }}
        >
          <SubTitle>{modals({ setModalActive, isMobile })[modalActive]['sub-title']}</SubTitle>
          <ContainerModal>{modals({ setModalActive, isMobile })[modalActive].children}</ContainerModal>
        </Modal>
      )}
    </ItemInvoice>
  )
}

import React from 'react'

export const IconDownload = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13M5.0002 8.0002L10.0002 13.0002M10.0002 13.0002L15.0002 8.0002M10.0002 13.0002L10 1"
      stroke="#006EAB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React, { useContext } from 'react'
import { Container, Form, Title, ButtonV3 } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { OptionsList, Option, OptionText, WrapperOption } from './style'
import { useWorkIncome } from './hooks'

export const WorkIncome = () => {
  const { goToNextScreen, incomeStatementPath, setDataModal } = useContext(TemplateDeclarationContext)
  const [selectedOption, onSelectOption, onSubmit, isButtonDisabled] = useWorkIncome(
    goToNextScreen,
    incomeStatementPath,
    setDataModal
  )
  return (
    <TemplateDeclaration>
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Você teve alguma renda relacionada a trabalho no mês passado?" />

          <OptionsList>
            {incomeStatementPath &&
              incomeStatementPath.ReadWorkIncome &&
              incomeStatementPath.ReadWorkIncome.options &&
              incomeStatementPath.ReadWorkIncome.options.length > 0 &&
              incomeStatementPath.ReadWorkIncome.options.map(item => {
                const text = item.displayName.split(',')
                return (
                  <WrapperOption key={item.id} onClick={() => onSelectOption(item)}>
                    <Option active={selectedOption && selectedOption.id === item.id}>
                      <OptionText bold active={selectedOption && selectedOption.id === item.id}>
                        {text[0]}
                        {text.length > 1 && ','}
                      </OptionText>
                      <OptionText active={selectedOption && selectedOption.id === item.id}>{text[1]}</OptionText>
                    </Option>
                  </WrapperOption>
                )
              })}
          </OptionsList>
          <ButtonV3 marginHorizontal={0} buttonProps={{type: 'submit'}} disabled={isButtonDisabled} text="Avançar" />
        </Form>
      </Container>
    </TemplateDeclaration>
  )
}

import React, { useContext, useEffect } from 'react'
import { Card, InputField, ButtonV3 } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'

import { PurchaseResume } from '../purchase-resume'
import { useAddressConfirmation } from './hooks'
import { Container, Wrapper, Form } from './styles'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'

export const AddressConfirmation = () => {
  const { isMobile, isLoading, setIsLoading } = useContext(TemplateContext)
  const {
    inputValues,
    purchaseResumeData,
    handleInputChange,
    handleOnSubmit,
    getAddressData,
    getPurchaseResumeData
  } = useAddressConfirmation(setIsLoading)

  useEffect(() => {
    getPurchaseResumeData()
    getAddressData()
  }, [])

  return (
    <Container>
      <Wrapper>
        <Card
          style={{
            maxWidth: 588,
            padding: '27px 20px'
          }}
        >
          <h1>Confirme seu endereço</h1>

          <h3>
            Essa confirmação é importante pois será o endereço para onde enviaremos os <br /> materiais de estudo.
          </h3>

          {isMobile && <h5>Endereço</h5>}

          <Form onSubmit={handleOnSubmit}>
            <div className="input-container">
              <InputField
                name="zipcode"
                value={inputValues?.zipcode}
                isValid={inputValues?.zipcode?.length > 0}
                isRequired={!inputValues?.zipcode?.length > 0}
                label="Cep"
                mask="99999-999"
                required
                onChange={handleInputChange}
              >
                <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="200%" />
              </InputField>
              <InputField
                name="street"
                value={inputValues?.street}
                isValid={inputValues?.street?.length > 0}
                isRequired={!inputValues?.street?.length > 0}
                label="Rua"
                required
                onChange={handleInputChange}
              >
                <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="200%" />
              </InputField>
              <div className="input-group">
                <InputField
                  name="number"
                  value={inputValues?.number}
                  isValid={inputValues?.number?.length > 0}
                  isRequired={!inputValues?.number?.length > 0}
                  isSmall
                  label="Número"
                  required
                  onChange={handleInputChange}
                >
                  <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="200%" />
                </InputField>
                <InputField
                  name="complement"
                  value={inputValues?.complement}
                  isValid={inputValues?.complement?.length > 0}
                  isSmall
                  label="Complemento"
                  onChange={handleInputChange}
                >
                  <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="200%" />
                </InputField>
              </div>

              <InputField
                name="state"
                value={inputValues?.state}
                isValid={inputValues?.state?.length > 0}
                isRequired={!inputValues?.state?.length > 0}
                label="Estado"
                required
                onChange={handleInputChange}
              >
                <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="200%" />
              </InputField>
              <InputField
                name="city"
                value={inputValues?.city}
                isValid={inputValues?.city?.length > 0}
                isRequired={!inputValues?.city?.length > 0}
                label="Cidade"
                required
                onChange={handleInputChange}
              >
                <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="200%" />
              </InputField>
              <InputField
                name="district"
                value={inputValues?.district}
                isValid={inputValues?.district?.length > 0}
                isRequired={!inputValues?.district?.length > 0}
                required
                label="Bairro"
                onChange={handleInputChange}
              >
                <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="200%" />
              </InputField>
            </div>

            <div className="buttonContainer">
              <ButtonV3 buttonProps={{type: 'submit'}} text="Avançar" />
            </div>
          </Form>
        </Card>
        {!isMobile && purchaseResumeData && <PurchaseResume purchaseResumeData={purchaseResumeData} />}
      </Wrapper>
    </Container>
  )
}

import React, { useContext, useEffect } from 'react'
import { ButtonV3, theme } from '@provi/provi-components'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { Container, Title, SubTitle, Text, ButtonsContainer, TextValue, Row } from './style'
import { currencyParser } from '../../../utils'

export const IncomeDeclarationFinished = () => {
  const { setDataModal, isMobile, goToNextScreen, incomeStatementPath } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])
  return (
    <TemplateDeclaration subTitle="Declaração de Renda">
      <Container>
        <Title>Sua declaração está quase no fim :)</Title>

        {incomeStatementPath?.readIncomeDeclarationFinished?.overrideUserIntendedPaymentValue &&
        incomeStatementPath?.readIncomeDeclarationFinished?.totalAmount ? (
          <>
            <SubTitle isBold>Confira os valores com base na declaração:</SubTitle>

            <Row marginTop>
              <TextValue color={theme.colors.blue900}>Pagamento com base na renda</TextValue>
              <TextValue bold color={theme.colors.blue600}>
                {currencyParser(incomeStatementPath?.readIncomeDeclarationFinished?.thresholdValue || 0, 'R$')}
              </TextValue>
            </Row>

            <Row>
              <TextValue color={theme.colors.blue900}>Pagamento adiantado</TextValue>
              <TextValue bold color={theme.colors.blue600}>
                {currencyParser(
                  incomeStatementPath?.readIncomeDeclarationFinished?.overrideUserIntendedPaymentValue || 0,
                  'R$'
                )}
              </TextValue>
            </Row>
            <hr />
            <Row>
              <TextValue color={theme.colors.blue900}>Saldo final</TextValue>
              <TextValue bold color={theme.colors.blue600}>
                {currencyParser(incomeStatementPath?.readIncomeDeclarationFinished?.totalAmount || 0, 'R$')}
              </TextValue>
            </Row>
          </>
        ) : null}

        <SubTitle color={isMobile} isBold>
          Antes de finalizar a declaração, topa responder mais duas perguntinhas?
        </SubTitle>

        <Text>
          {'Ajude a gente a conhecer melhor os seus interesses profissionais, '}
          {!isMobile && <br />}
          habilidades e dúvidas em relação ao mercado de trabalho.
          {!isMobile && <br />}
        </Text>
        <ButtonsContainer>
          <ButtonV3 color="light" onClick={() => goToNextScreen(false)} text="Enviar declaração" />
          <ButtonV3 icon marginHorizontal={0} marginVertical={0} onClick={() => goToNextScreen(true)} text="Responder" />
        </ButtonsContainer>
      </Container>
    </TemplateDeclaration>
  )
}

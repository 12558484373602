import React, { useContext, useEffect } from 'react'
import { theme, Title, ButtonV3 } from '@provi/provi-components'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { currencyParser } from '../../../utils'
import { Container, TextValue, Text, Row, ButtonsContainer, ContainerButton, TextIpca } from './style'

export const IncomeAboveThreshold = () => {
  const { goToNextScreen, incomeStatementPath, setDataModal } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  return (
    <TemplateDeclaration subTitle="Informações de Pagamento">
      <Container>
        <Title text="De acordo com a sua declaração, você deverá pagar uma parcela do ISA este mês." />
        <Text>
          O valor da parcela é calculado com base na renda que você declarou. Você vai receber um boleto para pagar até o{' '}
          próximo dia 25.
        </Text>

        <Row>
          <TextValue color={theme.colors.blue900}>Renda declarada: </TextValue>
          <TextValue color={theme.colors.blue900}>
            {currencyParser(incomeStatementPath?.readIncomeAboveThreshold?.response?.declaredValue || 0, 'R$')}
          </TextValue>
        </Row>
        <hr />
        <Row>
          <TextValue color={theme.colors.blue600}>Pagamento com base na renda:</TextValue>
          <TextValue color={theme.colors.blue600}>
            {currencyParser(incomeStatementPath?.readIncomeAboveThreshold?.response?.paymentValue || 0, 'R$')}
          </TextValue>
        </Row>

        <Text>
          Se você quiser, pode pagar um valor maior do que o indicado acima. Assim, você adianta uma parte das parcelas futuras
          e pode terminar de pagar o financiamento mais rápido!
        </Text>
        <TextIpca>{incomeStatementPath?.readIncomeAboveThreshold?.response?.IPCA}</TextIpca>

        <ButtonsContainer>
          <ContainerButton>
            <ButtonV3
              type="button"
              color="light"
              marginHorizontal={0}
              marginVertical={0}
              text="Pagar o valor mínimo"
              onClick={() => goToNextScreen(false)}
              icon
            />
          </ContainerButton>
          <ContainerButton>
            <ButtonV3
              marginHorizontal={0}
              marginVertical={0}
              text="Pagar um pouco mais"
              onClick={() => goToNextScreen(true)}
              icon
            />
          </ContainerButton>
        </ButtonsContainer>
      </Container>
    </TemplateDeclaration>
  )
}

import React from 'react'
import * as Yup from 'yup'
import _ from 'lodash'
import { withFormik } from 'formik'
import { ButtonV3, TextErrorInput, Form } from '@provi/provi-components'
import { InputMasks } from './styles'

const validatepaymentValue = value => {
  let result
  if (value === '') {
    result = true
  } else {
    if (value.replace(/\D/g, '') === '') {
      result = true
    } else {
      result = false
    }
  }
  return result
}

const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props

  return (
    <Form onSubmit={handleSubmit}>
      <InputMasks
        name="paymentValue"
        decimalSeparator=","
        thousandSeparator="."
        prefix="R$ "
        margin="normal"
        type="text"
        placeholder="R$ 30,00"
        onChangeEvent={handleChange('paymentValue')}
        onBlur={handleBlur('paymentValue')}
        error={!!errors.paymentValue}
        value={values.paymentValue}
        width="180px"
        maxLength={13}
      />
      {errors.paymentValue && touched.paymentValue && <TextErrorInput text={errors.paymentValue} />}

      <ButtonV3
        marginHorizontal={0}
        marginVertical={0}
        buttonProps={{type: 'submit'}}
        disabled={validatepaymentValue(values.paymentValue)}
        text="Avançar"
      />
    </Form>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ paymentValue: props.data ? JSON.stringify(props.data) : '' }),
  validationSchema: Yup.object().shape({
    paymentValue: Yup.string().required('Por favor, preencha o campo acima')
  }),
  handleSubmit: async ({ paymentValue }, { props }) => {
    let newValue = {
      value: paymentValue
    }
    newValue.value = _.replace(newValue.value, 'R$', '')
    newValue.value = _.replace(newValue.value, ' ', '')
    newValue.value = _.replace(newValue.value, '.', '')
    newValue.value = _.replace(newValue.value, ',', '.')

    props.goToNextScreen(parseFloat(newValue.value))
  }
})(Formik)

import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

const isSelectedStyles = css`
  background: #a9d9ff;
  border-radius: 8px;
`

const borderBottom = css`
  border-bottom: 1px solid #a9d9ff;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 496px;
  flex-direction: column;
  margin-bottom: 06px;
  ${props => props.selected && isSelectedStyles}
  ${props => !props.selected && borderBottom}

  padding: 8px;
`
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const Left = styled.div`
  display: flex;
  width: 100%;

  align-items: center;

  #checkBoxContainer {
    width: 20px;
    margin-right: 08px;
  }
`
export const ToggleButtonOptionDescription = styled.div`
  button {
    border: 1px solid #323b69;
    padding: 0;
    background: transparent;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    svg {
      width: 20px;
      height: 20px;
      color: #323b69;
    }
  }
`
export const OptionDescriptionContainer = styled.div`
  margin: 0 30px;
`
export const CheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: transparent;

  border-radius: 5px;
  border: 1px solid ${theme.colors.bluePrimaryDark};

  cursor: pointer;
`

export const SelectedBox = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${theme.colors.bluePrimary};
  border-radius: 2px;
`

import React, { useContext } from 'react'
import { SubTitle } from '@provi/provi-components'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import { MaxWidth } from '../../../components/max-width'
import { IconWhatsApp } from '../../../assets/svg/icon-whatsapp'
import { IconMail } from '../../../assets/svg/icon-email'
import { Text, Wrapper, ContainerBox, ContainerBoxText, Bold } from './styles'
import { useHelp, clickOnMail } from './hooks'
import { URL_EXTERNAL } from '../../../constants'

export const HelpContact = () => {
  const { setMenuActive, isMobile, setIsBack } = useContext(TemplateContext)
  useHelp(setMenuActive, setIsBack)

  return (
    <Template>
      <MaxWidth>
        <Wrapper>
          <SubTitle text="Ficou com alguma dúvida?" />
          <Text>
            Caso você tenha ficado com alguma dúvida a respeito de alguma informação encontrada no seu dashboard, você pode
            entrar em contato com a gente em algum dos canais abaixo.
          </Text>
          <Text>Entraremos em contato o mais breve possível.</Text>

          <ContainerBox onClick={() => window.open(URL_EXTERNAL, '_blank')}>
            <IconWhatsApp />
            <ContainerBoxText>
              Clique para falar com a gente <Bold>via WhatsApp</Bold>
            </ContainerBoxText>
          </ContainerBox>

          <ContainerBox onClick={() => clickOnMail(isMobile)}>
            <IconMail />
            <ContainerBoxText>
              Mande uma mensagem para papo@principia.net <Bold>via email</Bold>
            </ContainerBoxText>
          </ContainerBox>
        </Wrapper>
      </MaxWidth>
    </Template>
  )
}

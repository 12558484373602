import React, { useContext } from 'react'
import { SubHeaderDesktop, Container, Form, Title, ButtonV3, SubTitle, Option } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { ContainerOptions } from './style'
import { useExtraIncome } from './hooks'

export const ExtraIncome = () => {
  const { goToNextScreen, incomeStatementPath, setDataModal, setIsShowModal, isMobile } = useContext(TemplateDeclarationContext)
  const [selectedOption, onSelectOption, onSubmit, isButtonDisabled, options] = useExtraIncome(
    goToNextScreen,
    incomeStatementPath,
    setDataModal,
    setIsShowModal
  )
  return (
    <TemplateDeclaration>
      <Container>
        <Form onSubmit={onSubmit}>
          {isMobile ? (
            <Title text="Você teve algum outro tipo de renda mês passado?" />
          ) : (
            <SubHeaderDesktop
              title="Você teve algum outro tipo de renda mês passado?"
              handleModal={() => setIsShowModal(true)}
              isShowButtonHelpModal={true}
            />
          )}
          <SubTitle text="Favor considerar rendas provenientes de pensão, investimento, alugueis, mesadas, etc" />
          <ContainerOptions>
            {options.map(item => {
              return (
                <Option
                  key={item.id}
                  active={selectedOption && selectedOption.id === item.id}
                  func={() => onSelectOption(item)}
                  text={item.text}
                />
              )
            })}
          </ContainerOptions>

          <ButtonV3 marginHorizontal={0} buttonProps={{type: 'submit'}} disabled={isButtonDisabled} text="Avançar" />
        </Form>
      </Container>
    </TemplateDeclaration>
  )
}

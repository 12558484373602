import React, { useContext } from 'react'
import { ButtonV3, Title, Form } from '@provi/provi-components'

import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { Container, ContainerOptions, SubTitle, ButtonPosition } from './style'
import { useWorkIncomeType } from './hooks'
import { getPreviousMonthName } from '../../../utils'
import { Option } from '../../../components/option'

export const WorkIncomeType = () => {
  const { goToNextScreen, incomeStatementPath, setDataModal } = useContext(TemplateDeclarationContext)
  const { handlerSelectOption, onSubmit, isButtonDisabled, options } = useWorkIncomeType(
    goToNextScreen,
    incomeStatementPath,
    setDataModal
  )

  return (
    <TemplateDeclaration subTitle="Renda de trabalho">
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Por qual tipo de trabalho você recebeu?" />

          <div className="subTitleContainer">
            <SubTitle> Você pode selecionar mais de uma opção.</SubTitle>
            <SubTitle isBold>
              Lembre-se de considerar somente a renda que você recebeu <br /> no mês de {getPreviousMonthName()}.
            </SubTitle>
          </div>

          <ContainerOptions>
            {options.length > 0 &&
              options.map(option => <Option key={option.id} option={option} handlerSelectOption={handlerSelectOption} />)}
          </ContainerOptions>
          <ButtonPosition>
            <ButtonV3 buttonProps={{type: 'submit'}} marginHorizontal={0} marginVertical={20} disabled={isButtonDisabled} text="Avançar" />
          </ButtonPosition>
        </Form>
      </Container>
    </TemplateDeclaration>
  )
}

import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#006eab',
    },
    secondary: {
      main: '#575757',
    },
  },
})

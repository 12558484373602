import React, { useContext, useEffect } from 'react'
import { ButtonV3 } from '@provi/provi-components'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { Container, SubTitle, Text, ButtonsContainer } from './style'

export const IncomeBellowThreshold = () => {
  const { setDataModal, goToNextScreen, incomeStatementPath } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])
  return (
    <TemplateDeclaration subTitle="Informações de Pagamento">
      <Container>
        <SubTitle>De acordo com a sua declaração, você não precisa pagar nada do ISA este mês.</SubTitle>
        <Text>
          Mesmo assim, se você preferir, pode adiantar uma parte do pagamento.{' '}
          {incomeStatementPath?.readIncomeBelowThreshold?.IPCA}
        </Text>

        <ButtonsContainer>
          <ButtonV3 color="light" onClick={() => goToNextScreen(false)} text="Continuar declaração" />

          <ButtonV3 icon marginHorizontal={0} marginVertical={0} onClick={() => goToNextScreen(true)} text="Adiantar uma parte" />
        </ButtonsContainer>
      </Container>
    </TemplateDeclaration>
  )
}

import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { ButtonV3, Input, TextErrorInput, Form } from '@provi/provi-components'

const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit, isValid } = props
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        autoFocus={!values.skillDetails}
        margin="normal"
        type="text"
        multiline
        placeholder="Escreva aqui ..."
        onChange={handleChange('skillDetails')}
        onBlur={handleBlur('skillDetails')}
        error={!!errors.skillDetails}
        value={values.skillDetails}
        rowsMax={7}
      />
      {errors.skillDetails && touched.skillDetails && <TextErrorInput text={errors.skillDetails} />}

      <ButtonV3 marginHorizontal={0} disabled={!isValid} buttonProps={{type: 'submit'}} text="Avançar" />
    </Form>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ skillDetails: props.data ? props.data && props.data.value : '' }),
  validationSchema: Yup.object().shape({
    skillDetails: Yup.string()
      .required('Por favor, preencha o campo acima')
      .nullable()
  }),
  handleSubmit: async ({ skillDetails }, { props }) => {
    props.goToNextScreen(skillDetails || '')
  }
})(Formik)
